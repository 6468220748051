/*
Author: Singularity Solution
Author URI: https://www.singularity.is
Description: ZIP Stucki Park
Version: 1.0

* For the full copyright and license information, please view
* the LICENSE file that was distributed with this source code.
*/

@import "~bootstrap/dist/css/bootstrap.css";
@import "~ngx-toastr/toastr.css";
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap');

$color-gray: var(--secondary-color);
$color-green: var(--primary-color);
$color-light: rgba(0, 0, 0, .29);

body {
    font-family: 'Barlow', sans-serif;
    background: #fff;
    color: #000;
    line-height: 1.1;
    font-size: 80px;
}

a {
    color: #000;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: $color-gray;
    }
}

h1,
.h1 {
    margin: 0;
    font-size: 180px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
}

h2,
.h2 {
    margin: 0;
    font-size: 120px;
}

h5,
.h5 {
    margin: 0;
    font-size: 100px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;

    &.title {
        padding-top: 320px;
        padding-bottom: 100px;
    }
}

.btn {
    font-size: 80px;
    font-weight: 600;
    line-height: 1;
    padding: 50px 200px;
    border-radius: 0;

    -moz-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-green {
    background: var(--primary-color);
    color: #fff;
}

.btn-green:hover {
    background: var(--primary-light-color);
    color: #fff;
}

.container-fluid {
    padding-left: 200px;
    padding-right: 200px;
}

.bg-pattern {
    background-image: url(/assets/img/pattern.svg) !important;
    background-size: cover !important;
}




/**********************************
    COMMON CONTAINER - CODE
**********************************/

.code-block {
    position: relative;
    min-height: 2000px;
    padding-top: 250px;

    .close {
        position: absolute;
        right: 200px;
        top: 70px;

        img {
            width: 100px;
        }
    }

    h1 {
        margin-bottom: 100px;
    }

    p {
        font-weight: 300;
        margin-left: 150px;
        margin-bottom: 0;

        b,
        strong {
            font-weight: 600;
        }
    }

    .bg-gray {
        color: #fff;
        background: #82939E;
        margin: 100px -200px 0;
        padding: 80px 100px 80px 350px;
    }
}

.padlock {
    position: absolute;
    right: 400px;
    top: 200px;

    img {
        width: 250px;
    }

    &.verified {
        top: 180px;
        width: 500px;
        right: 300px;

        img {
            width: 300px;
        }
    }
}

.btn-outline-success {
    font-weight: 300;
    color: #000000;
    background: #fff;
    border: 5px solid var(--primary-color);
    text-align: center;
    display: block;
    padding: 40px 60px;

    &:hover {
        border-color: var(--primary-ligh-color);
        background: var(--primary-ligh-color);
    }
}

.btn-outline-secondary {
    font-weight: 300;
    color: var(--secondary-color);
    background: #fff;
    border: 5px solid var(--secondary-color);
    text-align: center;
    display: block;
    padding: 40px 60px;

    &:hover {
        border-color: var(--secondary-ligh-color);
        background: var(--secondary-ligh-color);
    }
}

@media (hover: hover) and (pointer: fine) {
    .btn-outline-secondary:hover {
        background: var(--secondary-light-color);
        border-color: var(--secondary-light-color);
        color: white;
    }
}

.calculator {
    border-color: var(--secondary-color);
}

.btn-enter {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
}

/** TOASTR OVERRIDES **/

.toast-container {
    max-width: 70%;

    &.toast-top-right {
        top: 20px;
        right: 20px;
    }

    > :not(:last-child) {
        margin-bottom: 20px;
    }

    >div {
        background-position: 50px center;
        background-repeat: no-repeat;
        background-size: 120px 120px;
        padding: 70px 70px 70px 250px;
    }
}
